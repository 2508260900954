import React from 'react'

import { SCROLL_ANIMATION_DEFAULT_PROPS } from '../../../constants'
import ScrollAnimation from '../../scroll-animation/Container'
import './restaurant-logos.less'

const RestaurantLogos = () => {
  return (
    <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
      <div className="restaurants-logos">
        <img className="restaurants-logos__logo-6" src="/images/restaurants-logos/nycDinner/logo_minetta.png" />
        <img className="restaurants-logos__logo-5" src="/images/restaurants-logos/nycDinner/logo_sweetgreen.png" />
        <img className="restaurants-logos__logo-1" src="/images/restaurants-logos/nycDinner/logo-shake-shack.png" />
        <img className="restaurants-logos__logo-2" src="/images/restaurants-logos/nycDinner/chipotle.png" />
        <img className="restaurants-logos__logo-2" src="/images/restaurants-logos/nycDinner/palmsmall.png" />
        <img className="restaurants-logos__logo-7" src="/images/restaurants-logos/nycDinner/luke.png" />
      </div>
    </ScrollAnimation>
  )
}

export default RestaurantLogos
