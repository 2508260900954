import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import Carousel from 'react-multi-carousel'

import useHomepage from '../../hooks/useHomepage'
import TestionialImage1 from '../../images/testimonial-1.jpg'
import TestionialImage2 from '../../images/testimonial-2.jpg'
import TestionialImage3 from '../../images/testimonial-3.jpg'
import CarouselButtonGroup from '../common/CarouselButtonGroup'
import ScrollAnimation from '../scroll-animation/Container'
import './testimonials.less'

const responsive = {
  desktop: {
    breakpoint: { max: 10000, min: 936 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 936, min: 0 },
    items: 1,
  },
}

const TestimonialsContainer = () => {
  const { t } = useTranslation()
  const { animationProps, onNyLunchPage, onSfLunchPage } = useHomepage()

  return (
    <div className="testimonials">
      <ScrollAnimation {...animationProps}>
        <div className="testimonials__title">{t('Join the MealPal community')}</div>
      </ScrollAnimation>
      <Carousel
        className="testimonials__carousel"
        responsive={responsive}
        arrows={false}
        renderButtonGroupOutside={true}
        customButtonGroup={<CarouselButtonGroup />}
      >
        <ScrollAnimation {...animationProps}>
          <div className="testimonials__tile">
            <img src={TestionialImage1} />
            <div className="testimonials__details">
              <p>“Saying goodbye to sad desk lunches! Now I can get my favorite salad for only $6! I love MealPal!!”</p>
              <div>
                <span className="testimonials__name">@coldsweatwithkacie</span>
                <span className="testimonials__location">Chicago, IL</span>
              </div>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation {...animationProps} delay={onNyLunchPage || onSfLunchPage ? 0 : 200}>
          <div className="testimonials__tile">
            <img src={TestionialImage2} />
            <div className="testimonials__details">
              <p>
                “I’m a huge fan of Blue Smoke burger. And with MealPal I can get it for only $6! I’m going to be eating
                this every week!!”
              </p>
              <div>
                <span className="testimonials__name">@danlikestoeat</span>
                <span className="testimonials__location">New York, NY</span>
              </div>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation {...animationProps} delay={onNyLunchPage || onSfLunchPage ? 0 : 400}>
          <div className="testimonials__tile">
            <img src={TestionialImage3} />
            <div className="testimonials__details">
              <p>
                “I just got the Tuna Poke Bowl from Poke Alice. It’s usually $14 and I just paid $6 for it. This is
                crazy!”
              </p>
              <div>
                <span className="testimonials__name">@MISH</span>
                <span className="testimonials__location">Seattle, WA</span>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </Carousel>
    </div>
  )
}

export default TestimonialsContainer
